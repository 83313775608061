import { For } from 'solid-js';
import { theme } from '@troon/tailwind-preset/theme';
import { addToast, Button, Heading } from '@troon/ui';
import { IconCopy } from '@troon/icons';
import { Title } from '@solidjs/meta';

export default function TextStylesPage() {
	return (
		<>
			<Title>Text / Styleguide</Title>
			<Heading as="h1">Text</Heading>

			<section class="flex flex-col gap-6">
				<Heading as="h2">Headings</Heading>

				<Heading as="p" size="h1">
					“H1” Lorem Ipsum is simply dummy text of the printing and typesetting industry.
				</Heading>
				<Heading as="p" size="h2">
					“H2” Lorem Ipsum is simply dummy text of the printing and typesetting industry.
				</Heading>
				<Heading as="p" size="h3">
					“H3” Lorem Ipsum is simply dummy text of the printing and typesetting industry.
				</Heading>
				<Heading as="p" size="h4">
					“H4” Lorem Ipsum is simply dummy text of the printing and typesetting industry.
				</Heading>
				<Heading as="p" size="h5">
					“H5” Lorem Ipsum is simply dummy text of the printing and typesetting industry.
				</Heading>
				<Heading as="p" size="h6">
					“H6” Lorem Ipsum is simply dummy text of the printing and typesetting industry.
				</Heading>
			</section>

			<section class="flex flex-col gap-6">
				<Heading as="h2">Size reference</Heading>
				<ul class="flex flex-col gap-8">
					<For each={Object.entries(theme!.fontSize!)}>
						{([key, [size]]) => (
							<li class="col-span-3 flex flex-col gap-2">
								<h3 class="flex flex-row-reverse items-center justify-end gap-4">
									text-{key}
									<Button
										appearance="transparent"
										class="flex size-full w-auto shrink grow-0 flex-col flex-nowrap items-start justify-center gap-2 overflow-hidden p-2 text-lg"
										onClick={() => {
											window.navigator.clipboard.writeText(`text-${key}`);
											addToast(
												<>
													Copied <code class="font-mono">text-{key}</code> to clipboard!
												</>,
												{
													timeout: 2000,
													variant: 'positive',
												},
											);
										}}
									>
										<IconCopy />
									</Button>
								</h3>
								<ul class="flex flex-row gap-2">
									<li>
										Size: {size} ({16 * parseFloat(size.replace('rem', ''))}px)
									</li>
								</ul>
								<p class="line-clamp-1 overflow-ellipsis" style={{ 'font-size': size }}>
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
									industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
									scrambled it to make a type specimen book.
								</p>
							</li>
						)}
					</For>
				</ul>
			</section>
		</>
	);
}
